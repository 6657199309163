// @flow
import React from 'react'
// import { Link } from 'gatsby'
import Layout, { SEO } from '../components/Layout'

const img1 = require('../images/home/dachstuhl-werner-alfred-bad.png')
const img2 = require('../images/home/energieforum-berlin.png')
const img3 = require('../images/home/fassade-comeniushof.png')

export default function Home() {
  return (
    <Layout>
      <SEO title="Metz Architekten" />

      <div className="content-aside" />

      <div className="content">
        <ul className="images-list">
          <li>
            <img className="imgleft" src={img1} alt="Energieforum Berlin" />
          </li>

          <li>
            <img src={img2} alt="Dachstuhl Werner-Alfred-Bad" />
          </li>

          <li>
            <img className="imgright" src={img3} alt="Fassade Comeniushof" />
          </li>
        </ul>
      </div>
    </Layout>
  )
}
